/*
 * AddUserDialog.tsx
 * Author: lnappenfeld
 * Date: 02.03.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import RolesSelectList from './RolesSelectList';
import keycloakfetch from '../../functions/keycloakfetch';
import settings from '../../config/settings';
import UsersSelectList from './UsersSelectList';
import {useForm} from 'react-hook-form';
import {ConfirmDialog} from 'primereact/confirmdialog';
import {showMessageOnError, showMessageOnSuccess} from '../global/CustomToast';

type Props = {
    projectId: any,
    onFinished: (success: boolean) => void
    setVisible: any
    type: string // 'user' or 'admin'
}

const UserAddForm = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common', 'users', 'roles', 'input', 'admins']);

    const [roleId, setRoleId] = useState('');
    const [userId, setUserId] = useState('');
    // boolean variable for confirm dialog
    const [visible, setVisible] = useState<boolean>(false);

    const defaultValues = {
        'role_id': '',
        'user_id': '',
    };

    const {control, formState: {errors}, handleSubmit, setValue} = useForm({defaultValues});

    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return errors[name] && <small className="p-error custom">{errors[name].message}</small>
    };

    const onSubmit = (data: any) => {
        console.log(data)
        // Nur wenn ein Benutzer Administrator werden soll, werden alle Verknüpfungen zu den Projekten aufgelöst
        // und der Warnhinweis im Dialog angezeigt. Bei Projektersteller und Beobachter bleiben die Verknüpfungen
        // if (props.type === 'admin') {
        if (data.role_id === 'bef4b0b6-2c04-432c-937d-b7790e88a4bc') {
            setVisible(true);
        } else {
            accept();
        }
    };

    const accept = () => {
        const projectId = props.type === 'admin' ? 'none' : props.projectId;
        const data = {
            'project_id': projectId,
            'role_id': roleId,
            'user_id': userId
        };

        keycloakfetch.put(settings.apiUrl + '/insertUserRole', data).then(result => {
            if (result.error) {
                showMessageOnError(t('error'), result.error);
            } else {
                showMessageOnSuccess( t('success'), props.type === 'admin' ? t('userManagement:toasts.adminAdded') : t('userManagement:toasts.userAdded'));
                props.onFinished(true);
                hideDialog()
            }
        });
    };

    const reject = () => {
        setVisible(false);
        hideDialog();
    };

    const hideDialog = () => {
        props.setVisible(false);
    };

    const getWarnMessage = (
        <React.Fragment>
            <Trans>
                <p>{t('userManagement:dialogs.addAdminDialog.warnMessage')}</p>
            </Trans>
        </React.Fragment>
    );

    return (
        <>
            <form id='formDialog' onSubmit={handleSubmit(onSubmit)}>
                <RolesSelectList
                    label={t('userManagement:global.role')}
                    projectId={props.type === 'admin' ? 'none' : props.projectId}
                    value={roleId}
                    setValue={setRoleId}
                    validationControl={control}
                    validationErrorMessage={getFormErrorMessage}
                    validationRules={{required: t('userManagement:global.role') + ' ' + t('input:required')}}
                />
                <UsersSelectList
                    type={props.type === 'admin' ? 'noneGlobalUsers' : 'noneProjectWithoutAdmins'}
                    label={t('userManagement:global.user')}
                    projectId={props.projectId}
                    value={userId}
                    setValue={setUserId}
                    validationControl={control}
                    validationErrorMessage={getFormErrorMessage}
                    validationRules={{required: t('userManagement:global.user') + ' ' + t('input:required')}}
                />
            </form>
            <ConfirmDialog
                visible={visible}
                onHide={() => setVisible(false)}
                message={getWarnMessage}
                header={t('warning')}
                icon="pi pi-exclamation-triangle"
                accept={accept}
                reject={reject}
                acceptLabel={t('yes')}
                rejectLabel={t('no')}
            />
        </>
    );
};

export default UserAddForm;
