import React, {useEffect, useRef, useState} from 'react';
import {useFieldArray} from 'react-hook-form';
import Input from '../global/Input';
import {Button} from 'primereact/button';
import {CustomConfirmDialog} from '../global/CustomConfirmDialog';
import {TabPanel, TabView} from 'primereact/tabview';
import {useGetIafCodesQuery, useGetIsoScopesQuery, useGetKompetenzQuery} from "../../redux/rtk/injectedPersonApi";
import {checkPermission} from "../../functions/functionLibrary";
import {permissions} from "../../config/permissions";
import StatusLight from "../global/StatusLight";

type Props = {
    t: any,
    getFormErrorMessage: (name: string) => void,
    form: any,
    edit: boolean,
}

export const Normen = (props: Props) => {
    const t = props.t;

    const [norm, setNorm] = useState<string>('');
    const [render, setRender] = useState<boolean>(false);

    const isoScopesQuery = useGetIsoScopesQuery({});
    const iafCodesQuery = useGetIafCodesQuery({});
    const kompetenzQuery = useGetKompetenzQuery({});

    const {control, formState: {errors}, handleSubmit, setValue, getValues, watch} = props.form;

    const id = 'kompetenzen';

    const {fields, append, prepend, remove, swap, move, insert} = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: id, // unique name for your Field Array
    });

    useEffect(() => {
        console.log('fields', fields);
        setRender(true);
    }, [fields]);

    const getDropdownOptions = (query: any) => {
        const result = query.data ? query.data.map((item: any) => {
            return {value: item.id, label: item.name};
        }) : [];

        return result;
    };
    const getDropdownOptionsValue = (query: any, value: string) => {
        const result = query.data ? query.data.find((item: any) => {
            return item.id === value;
        }) : [];

        return result ? result.name : '';
    };

    const getDropdownOptionsIsoScopes = () => {

        let ids = fields.map((item: any) => {
            return item.iso_code_id
        });

        const result = isoScopesQuery.data ? isoScopesQuery.data.filter((item: any) => {
            return ids.indexOf(item.id) < 0;
        }).map((item: any) => {
            return {value: item.id, label: item.name};
        }) : [];

        return result;
    };

    const getNormName = (norm_id: string) => {
        if (isoScopesQuery.data) {
            for (let i = 0; i < isoScopesQuery.data.length; i++) {
                let item = isoScopesQuery.data[i];
                if (item.id === norm_id) {
                    return item.name;
                }
            }
        }
        return '';
    }

    const getIafNames = (items: string[]) => {
        const iafCodes = iafCodesQuery.data ? iafCodesQuery.data.filter((isoItem: any) => {
            return items.indexOf(isoItem.id) >= 0;
        }).map((item: any) => {
            return item.iaf_code;
        }) : [];

        return iafCodes.join(' / ');
    }

    const getNormData = (item: any, index: number) => {
        const iafCodes = iafCodesQuery.data ? iafCodesQuery.data.filter((isoItem: any) => {
            return isoItem.iso_code_id === item.iso_code_id;
        }).map((item: any) => {
            return {value: item.id, label: item.iaf_code};
        }) : [];

        let selectedIafCodes = watch(`${id}.${index}.scopes`);
        console.log(item, `${id}.${index}`, item)
        return (<>
                <hr/>
                <div className={'grid mt-3'}>

                    <div className={'col-4'}>
                        {/*<h5>{t('persons:columns.norm')}</h5>*/}
                        <h5>{getDropdownOptionsValue(isoScopesQuery, getValues(`${id}.${index}.iso_code_id`))}</h5>
                    </div>
                    <div className={'col-4  m-auto'}>
                        <StatusLight id={'status-person'} value={item.ampel} info={''}
                                     light={false}
                                     horizontal={false} scale={1.2}/>
                    </div>
                    <div className={'col-4 m-auto'}>
                        {props.edit &&
                            <Button className={'p-button-rounded p-button-danger p-button-text mt-auto'}
                                    style={{float: 'right'}}
                                    type={'button'}
                                    icon={'pi pi-trash'}
                                    disabled={!props.edit}
                                    onClick={() => {
                                        CustomConfirmDialog({
                                            message: 'Möchten Sie diese Norm wirklich löschen?',
                                            header: 'Norm löschen',
                                            translation: t,
                                            onConfirm: () => {
                                                setRender(false);
                                                remove(index);
                                            }
                                        });
                                    }}/>}
                    </div>
                    <div className={'col-4'}>
                        <Input
                            edit={props.edit} label={t('persons:columns.kompetenz')}
                            name={`${id}.${index}.kompetenz_id`}
                            type={'dropdown'}
                            dropdownOptions={getDropdownOptions(kompetenzQuery)}
                            validationControl={control} validationErrorMessage={props.getFormErrorMessage}
                            validationRules={{required: t('persons:columns.kompetenz') + ' ' + t('input:required'),}}
                        />
                    </div>
                    <div className={'col-4'}>
                        <Input
                            edit={props.edit}
                            label={t('persons:columns.tagessatz')}
                            name={`${id}.${index}.tagessatz`}
                            // disabled={lastImport != null}
                            type={'number'}
                            maxFractionDigits={2}
                            validationControl={control} validationErrorMessage={props.getFormErrorMessage}
                            validationRules={{required: false,}}
                        />
                    </div>
                    <div className={'col-4'}>
                        <Input
                            edit={props.edit}
                            label={t('persons:columns.reise_zeit')}
                            name={`${id}.${index}.reise_zeit`}
                            //disabled={lastImport != null}
                            type={'number'}
                            validationControl={control} validationErrorMessage={props.getFormErrorMessage}
                            validationRules={{required: false,}}
                        />
                    </div>
                    <div className={'col-4'}>
                        <Input
                            edit={props.edit}
                            label={t('persons:columns.kilometergeld')}
                            name={`${id}.${index}.kilometergeld`}
                            // disabled={lastImport != null}
                            type={'number'}
                            maxFractionDigits={2}
                            validationControl={control} validationErrorMessage={props.getFormErrorMessage}
                            validationRules={{required: false,}}
                        />
                    </div>

                    <div className={'col-4'}>
                        <Input
                            edit={props.edit}
                            label={t('persons:columns.monitoring_gueltig_bis')}
                            name={`${id}.${index}.monitoring_gueltig_bis`}
                            type={'date'}
                            showTime={false}
                            validationControl={control} validationErrorMessage={props.getFormErrorMessage}
                            validationRules={{required: false}}
                        />
                    </div>
                    <div className={'col-4'}>
                        <Input
                            edit={props.edit} label={t('persons:columns.erfahrungsaustausch_gueltig_bis')}
                            name={`${id}.${index}.erfahrungsaustausch_gueltig_bis`}
                            type={'date'}
                            showTime={false}
                            validationControl={control} validationErrorMessage={props.getFormErrorMessage}
                            validationRules={{required: false}}
                        />
                    </div>
                    <div className={'col-12 md:col-4 py-0'}>
                        <Input
                            edit={props.edit}
                            label={t('persons:columns.gueltig_bis')}
                            name={`${id}.${index}.berufung_gueltig_bis`}
                            type={'date'}
                            showTime={false}
                            validationControl={control} validationErrorMessage={props.getFormErrorMessage}
                            validationRules={{required: false,}}
                        />
                    </div>
                    <div className={'col-4 m-auto'}>

                    </div>

                    {/*<div className={'col-12 py-0'}>*/}
                    {/*    <Input*/}
                    {/*        edit={props.edit} label={t('persons:columns.scopes')}*/}
                    {/*        name={`${id}.${index}.scopes`}*/}
                    {/*        type={'multiselect'}*/}
                    {/*        dropdownOptions={iafCodes}*/}
                    {/*        validationControl={control} validationErrorMessage={props.getFormErrorMessage}*/}
                    {/*        validationRules={{required: false,}}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div className={'col-12 py-0'}>
                        <h5>{t('persons:columns.scopes')}</h5>
                        {selectedIafCodes && <div className={'col-12 py-0'}>{getIafNames(selectedIafCodes)}</div>}
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className={'relative'} style={{minHeight: '30px'}}>
            {props.edit &&
                <div className={'grid'}>
                    <div className={'col-4'}>
                        <Input
                            edit={props.edit} label={t('persons:columns.normen')}
                            name={'normen'}
                            type={'dropdown'}
                            dropdownOptions={getDropdownOptionsIsoScopes()}
                            value={norm}
                            onChange={(e: any) => {
                                setNorm(e.target.value);
                            }}
                        />
                    </div>
                    <div className={'col-8'}>
                        <div className={'relative w-full h-full flex'}>
                            <Button
                                disabled={!norm}
                                className={'p-button-success p-button-outlined p-button-icon-only absolute'}
                                style={{left: '0', bottom: '0'}}
                                type={'button'} icon={'pi pi-plus'} onClick={() => {
                                append({
                                    'iso_code_id': norm,
                                    'isocode': getNormName(norm),
                                    'scopes': [],
                                    'person_id': '',
                                    'kompetenz_id': '',
                                    'monitoring': '',
                                    'monitoring_gueltig_bis': '',
                                    'tagessatz': '',
                                    'reise_zeit': '',
                                    'kilometergeld': ''
                                });
                                setNorm('');
                            }}/>
                        </div>
                    </div>
                </div>}
            {render && fields && fields.map((field, index) => {
                console.log(field, index)
                return getNormData(field, index);
            })}
        </div>
    );
};
