import React, {useEffect} from 'react';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHandPointer, faMaximize, faMinus, faPen, faPlus} from '@fortawesome/free-solid-svg-icons';

export enum CustomDialogHeaderType {
    Default = 1,
    Create = 2,
    Update = 3,
    Add = 4,
    Remove = 5,
    Select = 6
}

type Props = {
    onHide?: any,
    onCancel:()=>void,
    formName?: string,
    visible: boolean,
    header: any,
    children?:any,
    onClick?: () => void,
    submitText?: string,
    className?: string,
    style?: any,
    showFooter?:boolean,
    maximized?:boolean
    headerType?: CustomDialogHeaderType
    submitButtonDisabled?: boolean
    customButtons?: any
    customIcons?: any
}

const CustomDialog = (props: Props) => {
    const {t} = useTranslation(['common']);

    const dialogFooter = typeof props.showFooter === 'boolean' && !props.showFooter
        ? null
        :(
            <React.Fragment>
                { props.onClick &&
                <Button
                    type={'button'}
                    onClick={props.onClick}
                    label={t(props.submitText ? props.submitText : 'ok')}
                    icon="pi pi-check"
                    className="p-button-text"
                />}
                { props.formName &&
                <>
                    <Button
                        label={t('cancel')}
                        icon="pi pi-times"
                        className="p-button-text"
                        onClick={props.onCancel}
                    />
                    <Button
                        type='submit'
                        form={props.formName}
                        label={t(props.submitText ? props.submitText : 'save')}
                        icon="pi pi-check"
                        className="p-button-text"
                        disabled={typeof props.submitButtonDisabled === 'boolean' ? props.submitButtonDisabled : false}

                    />
                </>}
                {props.customButtons &&
                    props.customButtons}
            </React.Fragment>
        );

    const getHeader = () => {
        switch (props.headerType) {
            case CustomDialogHeaderType.Create:
                return (<><FontAwesomeIcon className='sidebar-icon' icon={faPlus}/> {props.header}</>);
            case CustomDialogHeaderType.Update:
                return (<><FontAwesomeIcon className='sidebar-icon' icon={faPen}/> {props.header}</>);
            case CustomDialogHeaderType.Add:
                return (<><FontAwesomeIcon className='sidebar-icon' icon={faPlus}/> {props.header}</>);
            case CustomDialogHeaderType.Remove:
                return (<><FontAwesomeIcon className='sidebar-icon' icon={faMinus}/> {props.header}</>);
            case CustomDialogHeaderType.Select:
                return (<><FontAwesomeIcon className='sidebar-icon' icon={faHandPointer}/> {props.header}</>);
            case CustomDialogHeaderType.Default:
                return (<>{props.header}</>);
            default:
                return (<>{props.header}</>);
        }
    };

    const getCustomIcons = () =>{
        if (props.customIcons) return props.customIcons;
        else return null;
    };

    return (
        <Dialog
            modal
            onHide={() => props.onCancel()}
            visible={props.visible}
            header={getHeader()}
            maximizable={true}
            icons={getCustomIcons()}
            maximized={props.maximized ? props.maximized : false}
            footer={dialogFooter}
            className={props.className}
            style={props.style}>
            {props.children}
        </Dialog>
    );
};

export default CustomDialog;