import React, {useEffect, useRef, useState} from 'react';
import {Card} from "primereact/card";
import {useGetLastImportQuery, useSapImportMutation} from "../../redux/rtk/injectedPersonApi";
import moment from "moment";
import CustomDialog from "../../components/global/CustomDialog";
import {Accordion, AccordionTab} from "primereact/accordion";
import {useTranslation} from "react-i18next";
import {Button} from "primereact/button";
import {showMessageOnError, showMessageOnWarn} from "../../components/global/CustomToast";
import {ProgressSpinner} from "primereact/progressspinner";
import Input from "../../components/global/Input";

const LastImport = () => {
    const {t} = useTranslation(['common']);

    const inputFile: any = useRef(null);

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [showLoading, setShowLoading] = useState<boolean>(false);

    const [search, setSearch] = useState<string>('');

    const {data: lastImport = undefined} = useGetLastImportQuery({});
    const [sapImport, resultSapImport] = useSapImportMutation();

    useEffect(() => {
        setShowLoading(resultSapImport.isLoading);
    }, [resultSapImport.isLoading]);

    useEffect(() => {
        if (resultSapImport.data) {
            let result = [];

            for (let i = 0; i < resultSapImport.data.length; i++) {
                let rsi = resultSapImport.data[i];
                result.push(`${rsi.person_nr}: ${rsi.info}`)
            }

            showMessageOnWarn('Info', result.join('\n'));
        }
    }, [resultSapImport.data]);

    const onAddDocument = () => {
        if (inputFile.current !== null) {
            inputFile.current.click();
        }
    };

    const getBase64 = (file: any, cb: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    const addTempFile = (e: any) => {
        setShowLoading(true);
        getBase64(e.target.files[0], (result: any) => {
            const arr = result.split('base64,');
            if (arr.length === 2) {

                const split = e.target.files[0].name.split('.');
                const extension = split[split.length - 1].toLowerCase();
                let file_type_id = 0;

                if (['xls', 'xlsx'].indexOf(extension) < 0) {
                    showMessageOnError(t('error'), t('errorMessages:fileExtensionNotSupportedError'));
                } else {
                    const data = arr[1];

                    sapImport({data: data}).then((result: any) => {
                        console.log(result);
                    });
                }
            }
        });
    };

    return <>
        <div className={'last-import-container flex'}>
            {lastImport &&
            <>
                <Card style={{cursor: 'pointer'}} onClick={() => {
                    setShowDialog(true);
                }}>
                    <span>SAP Import: </span>
                    <span>{moment(lastImport.import).format('DD.MM.yyyy HH:mm')}</span>
                    <span><i className={'ml-2 my-auto pi pi-eye'}/></span>
                </Card>
                <CustomDialog onCancel={() => {
                    setShowDialog(false)
                }} visible={showDialog} header={'SAP Import: ' + moment(lastImport.import).format('DD.MM.yyyy HH:mm')}>
                    <Input className={'mb-3'} edit={true} name={'search'} placeholder={'suchen...'} value={search} onChange={(e) => {
                        setSearch(e.target.value)
                    }}/>
                    <Accordion>
                        {lastImport.report.map((item: any) => {
                            let comp = Object.keys(item.comparison).length;
                            if (!search || (item.person_nr.indexOf(search) >= 0 || item.info.toLowerCase().indexOf(search.toLowerCase()) >= 0)) {
                                return <AccordionTab className={'last-import-accordion'}
                                                     header={`${item.person_nr} ${item.info ? ' - ' + item.info : comp > 0 ? (comp === 1 ? ' - 1 Unterschied ' : ' - ' + comp + ' Unterschiede ') : ' - Alles Ok!'}`}>
                                    {!item.info && comp === 0 && <div>Es gab keine Unterschiede zum SAP.</div>}
                                    {comp > 0 && <table>
                                        <tbody>
                                        <tr>
                                            <th>Feld</th>
                                            <th>DB</th>
                                            <th>SAP</th>
                                        </tr>
                                        {Object.keys(item.comparison).map((key: string) => {

                                            let keyText = key;

                                            if (key.startsWith('kompetenz_') ||
                                                key.startsWith('scopes_') ||
                                                key.startsWith('isocode_') ||
                                                key.startsWith('erfahrungsaustausch_gueltig_bis_') ||
                                                key.startsWith('monitoring_gueltig_bis_') ||
                                                key.startsWith('gueltig_bis_') ||
                                                key.startsWith('tagessatz_') ||
                                                key.startsWith('reise_zeit_') ||
                                                key.startsWith('kilometergeld_') ||
                                                key.startsWith('ampel_')
                                            ) {
                                                keyText = key
                                                    .replace('isocode_', t('persons:columns.normen') + ' ')
                                                    .replace('kompetenz_', t('persons:columns.kompetenzen') + ' ')
                                                    .replace('scopes_', t('persons:columns.scopes') + ' ')
                                                    .replace('erfahrungsaustausch_gueltig_bis_', t('persons:columns.erfahrungsaustausch_gueltig_bis') + ' ')
                                                    .replace('monitoring_gueltig_bis_', t('persons:columns.monitoring_gueltig_bis') + ' ')
                                                    .replace('gueltig_bis_', t('persons:columns.gueltig_bis') + ' ')
                                                    .replace('tagessatz_', t('persons:columns.tagessatz') + ' ')
                                                    .replace('reise_zeit_', t('persons:columns.reise_zeit') + ' ')
                                                    .replace('kilometergeld_', t('persons:columns.kilometergeld') + ' ')
                                                    .replace('ampel_', t('persons:columns.ampel') + ' ');
                                            } else {
                                                keyText = t('persons:columns.' + key)
                                            }

                                            return <tr>
                                                <td>{keyText}</td>
                                                <td>{item.comparison[key].db}</td>
                                                <td>{item.comparison[key].sap}</td>
                                            </tr>
                                        })}
                                        </tbody>
                                    </table>}
                                </AccordionTab>
                            }
                            return null;
                        })}
                    </Accordion>
                </CustomDialog>
            </>}
            <input
                type="file"
                id="file"
                ref={inputFile}
                accept={'.xls, .xlsx'}
                onInput={addTempFile}
                onClick={(e: any) => e.target.value = ''}
                style={{display: 'none'}}
            />
            <Button className={'p-button p-button-icon p-button-secondary ml-2'} icon={'pi pi-upload'}
                    onClick={onAddDocument}/>
        </div>
        {showLoading && <div className={'spinner'}>
            <ProgressSpinner/>
        </div>}
    </>
};

export default LastImport;
