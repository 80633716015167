import React from 'react';
import {getUserFromLocalStorage, Nullable} from "./Global";
import {ColumnObject, ColumnObjectType} from "../global/CustomDataTable";
import {FilterMatchMode, FilterOperator, FilterService} from "primereact/api";
import {filterTriStateCheckBox} from "../global/filter";
import Input from "../global/Input";
import {classNames} from "primereact/utils";
import moment from "moment-timezone";

export interface Person {
    "aktiv": boolean,
    "aquirierte_kunden": string,
    "anrede_id": string,
    "auditvolumen_vorjahr": number,
    "ausschluss_kollege": string,
    "ausschluss_kunde": string,
    "bearbeiter_id": string,
    "berufserfahrung": string,
    "count_protokoll_0": number,
    "count_protokoll_1": number,
    "count_protokoll_2": number,
    "datum_gespraech": Nullable<Date>,
    "einsatzbereich": string,
    "email": string,
    "freigabekompetenz": string,
    "geburtsdatum": string,
    "gewuenschtes_volumen": string,
    "id": string,
    "iaf_codes": any[],
    // "kompetenz": string,
    "kompetenzen": any[],
    "kompetenzenFilter": string,
    "kritikalitaet_id": string,
    // "kundenfeedback": any[],
    "land": string,
    "last_import": Nullable<Date>,
    "leistungsfeedback": number,
    "name": string,
    "normen": string,
    "ort": string,
    "kritikalitaet_info": string,
    "person_nr": Nullable<number>,
    "plz": string,
    "betreuuendes_pc_id": string,
    "qualifikation_zusaetzlich": string,
    "scopes": string,
    // "sprache": any[],
    "language": string,
    "strasse_hnr": string,
    "status_id": string,
    "telefon": string,
    "titel_id": string,
    "umsatz_vorjahr": number,
    // "vorkommnisse": any[],
    "vorname": string,
    "wettbewerber": string
    "wiedervorlage_datum": Nullable<Date>,
    "wiedervorlage_aktiv": boolean
}

export interface Konditionen {
    "id": string,
    "person_id": string,
    "tagessatz": number,
    "reise_zeit": number,
    "kilometergeld": number
}

const getColumnField = (t: any, name: string, display: boolean, args?: any) => {
    return {
        field: name,
        filterField: name,
        header: t('persons:columns.' + name),
        filter: true,
        display: display,
        style: {width: '125px'},
        ...args,
    };
}

export const getColumnFields = (t: any, types: any) => {
    // console.log('getColumnFields');

    const filterCheckboxIsVisible = (options: any) => {
        return filterTriStateCheckBox(options, t('showAll'), t('yes'), t('no'));
    };

    const getTypeName = (key: string, value: string, returnKey: string = 'name') => {
        let result = undefined;
        if (types[key] && value) {
            result = types[key].find((item: any) => {
                return item.id === value;
            });

            if (result) {
                result = result[returnKey];
            }
        }
        return result;
    }

    let result: ColumnObject[] = [];

    let user = getUserFromLocalStorage();

    result.push(getColumnField(t, "aktiv", true, {
        type: ColumnObjectType.Boolean,
        filterElement: filterCheckboxIsVisible
    }));
    result.push(getColumnField(t, "status_id", true, {
        body: (rowData: any) => {
            return <div>{getTypeName('statusBearbeitung', rowData['status_id'])}</div>
        },
        filterDropdownValues: types.statusBearbeitung ? types.statusBearbeitung.map((item: any) => {
            return {value: item.id, name: item.name}
        }) : []
    }));
    result.push(getColumnField(t, "datum_gespraech", false, {
        type: ColumnObjectType.Date,
        dateFormat: t('dateformatWithoutTime')
    }));

    result.push(getColumnField(t, "bearbeiter_id", true, {
        body: (rowData: any) => {
            return <div>{getTypeName('bearbeiter', rowData['bearbeiter_id'])}</div>
        },
        filterDropdownValues: types.bearbeiter ? types.bearbeiter.map((item: any) => {
            return {value: item.id, name: item.name}
        }) : []
    }));

    result.push(getColumnField(t, "person_nr", true));
    result.push(getColumnField(t, "anrede_id", true, {
        body: (rowData: any) => {
            return <div>{getTypeName('anrede', rowData['anrede_id'])}</div>
        },
        filterDropdownValues: types.anrede ? types.anrede.map((item: any) => {
            return {value: item.id, name: item.name}
        }) : []
    }));
    result.push(getColumnField(t, "titel_id", true, {
        body: (rowData: any) => {
            return <div>{getTypeName('titel', rowData['titel_id'])}</div>
        },
        filterDropdownValues: types.titel ? types.titel.map((item: any) => {
            return {value: item.id, name: item.name}
        }) : []
    }));
    result.push(getColumnField(t, "name", true));
    result.push(getColumnField(t, "vorname", true));
    result.push(getColumnField(t, "plz", true));
    result.push(getColumnField(t, "ort", true));
    result.push(getColumnField(t, "land", true));
    result.push(getColumnField(t, "telefon", false));
    result.push(getColumnField(t, "email", false));
    result.push(getColumnField(t, "geburtsdatum", false, {
        type: ColumnObjectType.Date,
        dateFormat: t('dateformatWithoutTime')
    }));

    result.push(getColumnField(t, "qualifikation_zusaetzlich", true));
    // result.push(getColumnField(t, "normen", false));
    // result.push(getColumnField(t, "scopes", false));

    let kompetenzen = [];

    if (types.isoScopes) {
        kompetenzen.push(...types.isoScopes.map((item: any) => {
            return {value: item.name, name: item.name}
        }));
    }

    // if (types.iafCodes) {
    //     kompetenzen.push(...types.iafCodes.map((item: any) => {
    //         return {value: item.id, name: item.iaf_code}
    //     }));
    // }

    // result.push(getColumnField(t, "kompetenzen", true, {
    //     body: (rowData: any) => {
    //         let result = rowData['kompetenzen'] ? rowData['kompetenzen'].map((item: any) => {
    //             return `${item.isocode}, ${getTypeName('kompetenz', item.kompetenz_id)}, (${item.scopes ? item.scopes.map((scope: any) => {
    //                 return getTypeName('iafCodes', scope, 'iaf_code');
    //             }).join(' / ') : ''})`
    //         }).join('], [') : '';
    //
    //         return result ? <div>[{result}]</div> : <div></div>
    //     },
    //     filter: true,
    //     // filterDropdownValues: kompetenzen
    // }));
    result.push(getColumnField(t, "kompetenzenFilter", true, {
        showFilterMatchModes: false,
        body: (rowData: any) => {
            return <div>{rowData['kompetenzenFilter'] ? rowData['kompetenzenFilter'].label : ''}</div>
        },
        filterElement: (options: any) => {
            return <>
                <Input edit={true} name={'normen'} type={'dropdown'} className={'mb-2'}
                       value={options.value ? options.value.norm : ''}
                       dropdownOptions={types.isoScopes.map((item: any) => {
                           return {value: item.id, label: item.name};
                       })}
                       onChange={(e: any) => {
                           options.value = '';
                           options.filterApplyCallback({norm: e.value, scopes: options.value.scopes}, options.index);

                       }}/>
                <Input edit={true} name={'scopes'} type={'multiselect'}
                       value={options.value ? options.value.scopes : ''}
                       dropdownOptions={options.value ? types.iafCodes.filter((item: any) => {
                           return item.iso_code_id === options.value.norm
                       }).map((item: any) => {
                           return {value: item.id, label: item.iaf_code};
                       }) : []}
                       onChange={(e: any) => {
                           options.filterApplyCallback({norm: options.value.norm, scopes: e.value}, options.index);
                       }}/>
            </>
        }
    }));
    result.push(getColumnField(t, "einsatzbereich", true));
    result.push(getColumnField(t, "language", true));
    // result.push(getColumnField(t, "sprache", true, {
    //     body: (rowData: any) => {
    //         let result = rowData['sprache'] ? rowData['sprache'].map((item: any) => {
    //             return getTypeName('sprache', item)
    //         }).join(', ') : '';
    //
    //         return <div>{result}</div>
    //     },
    //     // filterField: 'sprache',
    //     filterDropdownValues: types.sprache ? types.sprache.map((item: any) => {
    //         return {value: item.id, name: item.name}
    //     }) : []
    //     // filterDropdownField: 'sprache'
    // }));
    result.push(getColumnField(t, "wettbewerber", false));
    result.push(getColumnField(t, "aquirierte_kunden", false));

    result.push(getColumnField(t, "ausschluss_kollege", false));
    result.push(getColumnField(t, "ausschluss_kunde", false));
    result.push(getColumnField(t, "gewuenschtes_volumen", true));
    result.push(getColumnField(t, "auditvolumen_vorjahr", true));
    result.push(getColumnField(t, "umsatz_vorjahr", true));
    result.push(getColumnField(t, "kritikalitaet_id", true, {
        body: (rowData: any) => {
            return <div>{getTypeName('kritikalitaet', rowData['kritikalitaet_id'])}</div>
        },
        filterDropdownValues: types.kritikalitaet ? types.kritikalitaet.map((item: any) => {
            return {value: item.id, name: item.name}
        }) : []
    }));
    result.push(getColumnField(t, "leistungsfeedback", false));
    result.push(getColumnField(t, "kritikalitaet_info", false));
    result.push(getColumnField(t, "betreuuendes_pc_id", false, {
        body: (rowData: any) => {
            return <div>{getTypeName('profitcenter', rowData['betreuuendes_pc_id'])}</div>
        },
        filterDropdownValues: types.profitcenter ? types.profitcenter.map((item: any) => {
            return {value: item.id, name: item.name}
        }) : [],
        style: {width: '200px'},
    }));

    result.push(getColumnField(t, "wiedervorlage_datum", false, {
        body: (rowData: any) => {
            const timezone = user.timezone;

            let value = '';

            let active = new Date(rowData['wiedervorlage_datum']) < new Date() && rowData['wiedervorlage_aktiv'];

            if (rowData['wiedervorlage_datum']) {
                if (timezone) {
                    value = moment(rowData['wiedervorlage_datum']).tz(timezone).format(t('dateformatWithoutTime'));
                } else {
                    value = moment(rowData['wiedervorlage_datum']).format(t('dateformatWithoutTime'));
                }
            }

            return (<div>
                <div style={active ? {color: 'red', fontWeight: 'bold'} : undefined}>
                    {value !== 'Invalid date' ? value : '-'}
                </div>
            </div>);
        }
    }));
    result.push(getColumnField(t, "wiedervorlage_aktiv", false, {
        body: (rowData: any) => {
            return <div>
                <i className={classNames('pi', rowData['wiedervorlage_aktiv'] ? 'true-icon pi-check-circle' : 'false-icon pi-minus')}
                />
            </div>
        }
    }));
    // result.push(getColumnField(t, "vorkommnisse", false));
    // result.push(getColumnField(t, "kundenfeedback", false));
    result.push(getColumnField(t, "count_protokoll_0", false, {filter: false}));
    result.push(getColumnField(t, "count_protokoll_1", false, {filter: false}));
    result.push(getColumnField(t, "count_protokoll_2", false, {filter: false}));


    return result;
}

const getFilterField = (name: string, args?: any) => {
    return {
        [name]: args && args.bool ? {
            value: true,
            matchMode: FilterMatchMode.EQUALS,
            ...args
        } : {
            operator: FilterOperator.AND,
            constraints: [{value: args && args.value != null ? args.value : null, matchMode: FilterMatchMode.CONTAINS}]
        },
    }
}

export const getFilterFields = () => {
    FilterService.register('custom_kompetenzenFilter', (value, filters) => {
        let result = true;
        if (filters) {
            if (value) {
                if (!filters.scopes || !filters.scopes.length) {

                    let normen = value.values.map((item: any) => {
                        return item.isoCode
                    });

                    result = normen.indexOf(filters.norm) >= 0;

                } else {
                    let found = false
                    for (let i = 0; i < value.values.length; i++) {
                        let item = value.values[i];

                        if (item.isoCode === filters.norm) {
                            found = true;
                            result = filters.scopes.every((v: any) => item.scopes.includes(v));
                        }
                        if (!result) {
                            break;
                        }
                    }

                    if (result) {
                        result = found;
                    }
                }
            } else {
                result = false;
            }
        }
        return result;
    });


    return {
        'ampel': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]
        },
        ...getFilterField("aktiv", {value: true, bool: true}),
        ...getFilterField("aquirierte_kunden"),
        ...getFilterField("anrede"),
        ...getFilterField("auditvolumen_vorjahr"),
        ...getFilterField("ausschluss_kollege"),
        ...getFilterField("ausschluss_kunde"),
        ...getFilterField("bearbeiter_id"),
        ...getFilterField("berufserfahrung"),
        ...getFilterField("einsatzbereich"),
        ...getFilterField("email"),
        ...getFilterField("datum_gespraech"),
        ...getFilterField("geburtsdatum"),
        ...getFilterField("gewuenschtes_volumen"),
        ...getFilterField("id"),
        // ...getFilterField("kompetenzenFilter"),
        'kompetenzenFilter': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.CUSTOM}]
        },
        ...getFilterField("konditionen"),
        ...getFilterField("kritikalitaet"),
        ...getFilterField("kundenfeedback"),
        ...getFilterField("leistungsfeedback"),
        ...getFilterField("name"),
        ...getFilterField("normen"),
        ...getFilterField("kritikalitaet_id"),
        ...getFilterField("kritikalitaet_info"),
        ...getFilterField("person_nr"),
        ...getFilterField("betreuuendes_pc_id"),
        ...getFilterField("qualifikation_zusaetzlich"),
        ...getFilterField("scopes"),
        ...getFilterField("language"),
        // ...getFilterField("sprache"),
        ...getFilterField("status_id"),
        ...getFilterField("telefon"),
        ...getFilterField("titel_id"),
        ...getFilterField("umsatz_vorjahr"),
        ...getFilterField("vorkommnisse"),
        ...getFilterField("vorname"),
        ...getFilterField("wettbewerber"),
        ...getFilterField("plz"),
        ...getFilterField("ort")
    }
}
