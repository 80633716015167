import React, {useEffect, useRef, useState} from 'react';
import {Button} from 'primereact/button';
import {useTranslation} from 'react-i18next';
import '../../style/file-management.scss';
import {Image} from 'primereact/image';
import {CustomConfirmDialog} from './CustomConfirmDialog';
import {deleteFile, getFiles, insertFile} from '../functions/Global';
import {showMessageOnError, showMessageOnSuccess} from './CustomToast';
import {hideWaitAnimation, showWaitAnimation} from './CustomWaitAnimation';
import CustomDialog from "./CustomDialog";
import {Document} from 'react-pdf'
import {ProgressSpinner} from "primereact/progressspinner";

type Nullable<T> = T | null;

type Props = {
    person_id: string,
    edit: boolean,
    maxSize?: number
    onSelect?: (id: string, sizeInBytes: number) => void,
}

type File = {
    id: string,
    name: string,
    type_id: number,
    type_name: string,
    data: string,
    size: number
}

const FileManagement = (props: Props) => {

    const inputFile: any = useRef(null);
    const {t} = useTranslation(['common']);

    const [files, setFiles] = useState<File[]>([]);
    const [selectedContent, setSelectedContent] = useState<any>(undefined);
    const [loadingSpinner, setLoadingSpinner] = useState<boolean>(false);

    useEffect(() => {
        if (props.person_id) {
            _getFiles();
        }
    }, []);

    const _getFiles = () => {
        // showWaitAnimation();
        setLoadingSpinner(true);
        getFiles(props.person_id).then(result => {

            for (let j = 0; j < result.length; j++) {
                let file = result[j];

                file.size = base64ToBytes(file.data);
                console.log(file.size);
            }

            setFiles(result);
            setLoadingSpinner(false);
            hideWaitAnimation();
        });
    };

    const base64ToBytes = (base64: string) => {
        return 4 * Math.ceil((base64.length / 3)) * 0.5624896334383812;
    };

    const getSizeString = (bytes: number) => {
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(1024));

        return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
    };

    const chooseFile = (e: any) => {
        if (inputFile.current !== null) {
            inputFile.current.click();
        }
    };

    const getBase64 = (file: any, cb: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    const uploadFile = (e: any) => {

        if (e.target.files[0].size > 5000000) {
            showMessageOnError(t('error'), t('errorMessages:fileExceed5MbLimitError'));
        } else {
            getBase64(e.target.files[0], (result: any) => {
                const arr = result.split('base64,');
                if (arr.length === 2) {

                    const split = e.target.files[0].name.split('.');
                    const extension = split[split.length - 1].toLowerCase();
                    let file_type_id = 0;

                    switch (extension) {
                        case 'png':
                            file_type_id = 0;
                            break;

                        case 'jpg':
                        case 'jpeg':
                            file_type_id = 1;
                            break;

                        case 'pdf':
                            file_type_id = 2;
                            break;
                    }


                    const body = {
                        person_id: props.person_id,
                        file_type_id: file_type_id,
                        data: arr[1],
                        name: e.target.files[0].name
                    };

                    // showWaitAnimation();
                    setLoadingSpinner(true);
                    insertFile(body).then(result => {
                        setLoadingSpinner(false);
                        hideWaitAnimation();
                        if (result.error) {
                            showMessageOnError(t('error'), t(result.error));
                        } else {
                            showMessageOnSuccess(t('success'), t('projectManagement:toasts.fileUploaded'));
                            _getFiles();
                        }
                    });
                }
            });
        }
    };

    const showFile = (file: File, index: number) => {
        let content;

        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(file.size) / Math.log(1024));

        const sizeStr = `${parseFloat((file.size / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;

        switch (file.type_name.toLowerCase()) {
            case 'png':
            case 'jpeg':
            case 'jpg':
                content = (
                    <Image
                        className="fm-image" src={'data:image/' + file.type_name + ';base64, ' + file.data}
                        preview width="80"
                    />
                );
                break;

            default:
                // content = (<object className={'w-full h-full'} data={'data:application/pdf;base64, ' + file.data}
                //                    type="application/pdf">
                //     <div>No PDF viewer available</div>
                // </object>)
                // content = <Document file={'data:application/pdf;base64, ' + file.data}/>
                break;
        }

        return (
            <div id="select-button"
                 key={file.name + '_' + index}
                 className={'w-full h-auto grid p-2 fm-content' + (selectedContent?.id === file.id ? ' selected' : '')}
                 onClick={(e: any) => {
                     if (e.target.id !== 'delete-button') {
                         setSelectedContent(file);
                         if (props.onSelect) {
                             props.onSelect(file.id, file.size);
                         }
                     }
                 }}>
                <div className={'col'}>
                    <div className="fm-filename">{file.name}</div>
                </div>
                <div className={'col-fixed'}>
                    <div className={'pr-3'}>{'(' + sizeStr + ')'}</div>
                    {props.edit && <Button id="delete-button" type="button" icon="pi pi-times"
                                           className="p-button-rounded p-button-danger p-button-text fm-delete-button"
                                           style={{top: '8px'}}
                                           aria-label="Delete" onClick={(e) => {
                        console.log(e);
                        CustomConfirmDialog({
                            message: t('projectManagement:dialogs.deleteFile.message'),
                            header: t('confirmation'),
                            translation: t,
                            onConfirm: () => {
                                deleteFile(props.person_id, file.id).then(result => {
                                    if (result.error) {
                                        showMessageOnError(t('error'), t('errorMessages:' + result.error));
                                    } else {
                                        showMessageOnSuccess(t('success'), t('projectManagement:toasts.fileDeleted'));
                                        _getFiles();
                                    }
                                });
                            }
                        });
                    }}
                    />}
                </div>

                {/*<div className="mt-2">{content}</div>*/}
                {/*<div className="fm-filename">{file.name}</div>*/}
                {/*<div>{'(' + sizeStr + ')'}</div>*/}
                {/*<Button id="delete-button" type="button" icon="pi pi-times"*/}
                {/*        className="p-button-rounded p-button-danger p-button-text fm-delete-button"*/}
                {/*        aria-label="Delete" onClick={(e) => {*/}
                {/*    console.log(e);*/}
                {/*    CustomConfirmDialog({*/}
                {/*        message: t('projectManagement:dialogs.deleteFile.message'),*/}
                {/*        header: t('confirmation'),*/}
                {/*        translation: t,*/}
                {/*        onConfirm: () => {*/}
                {/*            deleteFile(props.person_id, file.id).then(result => {*/}
                {/*                if (result.error) {*/}
                {/*                    showMessageOnError(t('error'), t('errorMessages:' + result.error));*/}
                {/*                } else {*/}
                {/*                    showMessageOnSuccess(t('success'), t('projectManagement:toasts.fileDeleted'));*/}
                {/*                    _getFiles();*/}
                {/*                }*/}
                {/*            });*/}
                {/*        }*/}
                {/*    });*/}
                {/*}}*/}
                {/*/>*/}
            </div>
        );
    };

    return (
        <div>
            <h4>{'Dateien (' + (files.length | 0) + ')'}</h4>
            <div className="grid grid-nogutter mt-3 fm">

                {loadingSpinner && <ProgressSpinner
                    className={'absolute'}
                    style={{width: '50px', height: '50px', margin: 'auto', zIndex: '4000'}}
                    animationDuration=".5s"
                />}
                {props.maxSize && <div>{getSizeString(props.maxSize)}</div>}
                <div className="grid grid-nogutter col fm-body">
                    {files && files.length > 0 && files.map((file, index: number) => {
                        return showFile(file, index);
                    })}
                </div>
                {props.edit && <div className="fm-button-controls p-2">
                    <input
                        type="file"
                        id="file"
                        ref={inputFile}
                        accept=".pdf"
                        onInput={uploadFile}
                        style={{display: 'none'}}
                    />
                    <Button
                        type="button"
                        className="p-button p-button-outlined" style={{backgroundColor: 'white'}}
                        icon="pi pi-plus" onClick={chooseFile}
                    />
                </div>}
                {selectedContent &&
                <CustomDialog maximized={true} onCancel={() => {
                    setSelectedContent('')
                }} visible={selectedContent} header={selectedContent.name}>
                    <object className={'w-full h-full'} data={'data:application/pdf;base64, ' + selectedContent.data}
                            type="application/pdf">
                        <div>No PDF viewer available</div>
                    </object>
                </CustomDialog>}
            </div>
        </div>
    );
};

export default FileManagement;
