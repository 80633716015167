import {baseApi} from './baseApi';

export const injectedPersonApi = baseApi.injectEndpoints({
    addTagTypes: ['Person'],
    endpoints: (builder) => ({
        getPerson: builder.query({
            query: (args) => {

                let {personId} = args;
                let url = `getPerson`;

                if (personId) {
                    url += '?person_id=' + personId;
                }

                return {url: url};
            },
            providesTags: ['Person']
        }),
        getPersonKompetenz: builder.query({
            query: (args) => {

                let {personId} = args;
                let url = `getPersonKompetenz`;

                if (personId) {
                    url += '?person_id=' + personId;
                }

                return {url: url};
            },
            providesTags: ['Person']
        }),
        createPerson: builder.mutation({
            query: (data) => ({
                url: 'createPerson',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Person']
        }),
        updatePerson: builder.mutation({
            query: (data) => ({
                url: 'updatePerson',
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Person']
        }),
        deletePerson: builder.mutation({
            query: (data) => ({
                url: 'deletePerson',
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Person']
        }),
        toggleActiveStatePerson: builder.mutation({
            query: (data) => ({
                url: 'toggleActiveStatePerson',
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Person']
        }),
        // deleteContract: builder.mutation({
        //     query: (data) => ({
        //         url: 'deleteContract',
        //         method: 'DELETE',
        //         body: data,
        //     }),
        //     invalidatesTags: ['Person']
        // }),
        getAnrede: builder.query({
            query: (args) => {
                let url = `getAnrede`;

                return {url: url};
            },
            providesTags: ['Person']
        }),
        getTitle: builder.query({
            query: (args) => {
                let url = `getTitle`;

                return {url: url};
            },
            providesTags: ['Person']
        }),
        getBearbeiter: builder.query({
            query: (args) => {
                let url = `getBearbeiter`;

                return {url: url};
            },
            providesTags: ['Person']
        }),
        getDoubletten: builder.query({
            query: (args) => {
                let {name, vorname} = args;
                let url = `getDoubletten`;

                if (name&&vorname) {
                    url += '?name=' + name+'&vorname='+vorname;
                };

                return {url: url};
            },
            providesTags: ['Person']
        }),
        getEinsatzbereich: builder.query({
            query: (args) => {
                let url = `getEinsatzbereich`;

                return {url: url};
            },
            providesTags: ['Person']
        }),
        getKompetenz: builder.query({
            query: (args) => {
                let url = `getKompetenz`;

                return {url: url};
            },
            providesTags: ['Person']
        }),
        getKunde: builder.query({
            query: (args) => {
                let url = `getKunde`;

                return {url: url};
            },
            providesTags: ['Person']
        }),
        getKritikalitaet: builder.query({
            query: (args) => {
                let url = `getKritikalitaet`;

                return {url: url};
            },
            providesTags: ['Person']
        }),
        getLanguage: builder.query({
            query: (args) => {
                let url = `getLanguage`;

                return {url: url};
            },
            providesTags: ['Person']
        }),
        getStatusbearbeitung: builder.query({
            query: (args) => {
                let url = `getStatusbearbeitung`;

                return {url: url};
            },
            providesTags: ['Person']
        }),
        getWettbewerber: builder.query({
            query: (args) => {
                let url = `getWettbewerber`;

                return {url: url};
            },
            providesTags: ['Person']
        }),
        getIsoScopes: builder.query({
            query: (args) => {
                let url = `getIsoScopes`;

                return {url: url};
            },
            providesTags: ['Person']
        }),
        getIafCodes: builder.query({
            query: (args) => {
                let url = `getIafCodes`;

                return {url: url};
            },
            providesTags: ['Person']
        }),
        getProfitcenter: builder.query({
            query: (args) => {
                let url = `getProfitcenter`;

                return {url: url};
            },
            providesTags: ['Person']
        }),
        getLastImport: builder.query({
            query: (args) => {
                let url = `getLastImport`;

                return {url: url};
            },
            providesTags: ['Person']
        }),
        searchFiles: builder.query({
            query: (args) => {
                let {text, and} = args;
                let url = `searchFiles?text=` + text + '&and=' + and;

                return {url: url};
            },
            providesTags: ['Person']
        }),
        sapImport: builder.mutation({
            query: (data) => ({
                url: 'sapImport',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Person']
        }),
        pcToPlz: builder.mutation({
            query: (data) => ({
                url: 'pcToPlz',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Person']
        }),
    }),
});

export const {
    useGetPersonQuery,
    useGetPersonKompetenzQuery,
    useLazyGetPersonQuery,
    useCreatePersonMutation,
    useUpdatePersonMutation,
    useToggleActiveStatePersonMutation,
    useGetAnredeQuery,
    useGetTitleQuery,
    useGetKritikalitaetQuery,
    useGetBearbeiterQuery,
    useGetEinsatzbereichQuery,
    useGetKompetenzQuery,
    useGetKundeQuery,
    useGetLanguageQuery,
    useGetStatusbearbeitungQuery,
    useGetWettbewerberQuery,
    useGetIsoScopesQuery,
    useGetIafCodesQuery,
    useGetProfitcenterQuery,
    useGetLastImportQuery,
    useLazySearchFilesQuery,
    useLazyGetDoublettenQuery,
    useSapImportMutation,
    usePcToPlzMutation,
    useDeletePersonMutation,
} = injectedPersonApi;
