import React from 'react';
import {classNames} from 'primereact/utils';

type Props = {
    left: any,
    right: any,
}

const EditDialogLayout = (props: Props) => {
    return (<div className="grid h-full">
        <div className={classNames('col-12 h-full', props.right ? 'lg:col-6 xl:col-4' : '')}>
            <div className={'card h-full mb-0 overflow-x-hidden overflow-y-auto'}>
                {props.left}
            </div>
        </div>
        {props.right && <div className="col-12 lg:col-6 xl:col-8 h-full">
            <div className={'card h-full mb-0 overflow-x-hidden overflow-y-auto'}>
                {props.right}
            </div>
        </div>}
    </div>);

};

export default EditDialogLayout;
