/*
 * UserManagement.tsx
 * Author: lnappenfeld
 * Date: 14.11.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {checkPermission} from '../functions/functionLibrary';
import {permissions} from '../config/permissions';
import ViewButtons from '../components/global/ViewButtons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser, faUserSecret, faUserTag} from '@fortawesome/free-solid-svg-icons';
import UsersComponents from '../components/userManagement/UsersComponents';
import {
    buttonAddAdmin,
    buttonAddUser,
    buttonCreateAdmin,
    buttonCreateRole,
    buttonCreateUser,
    buttonMoveUsersToRole,
    buttonRemoveGlobalUsers,
    buttonRemoveUsersFromProject,
    makeButtonItem
} from '../components/global/viewButtonSelection';
import Roles from '../components/userManagement/Roles';
import {Role} from '../components/functions/UserManagement';
import LayoutMainContentTabMenu from '../components/global/LayoutMainContentTabMenu';
import {Nullable} from '../components/functions/Global';

type Props = {
}

const UserManagement = (props: Props): JSX.Element => {

    const {t} = useTranslation(['common']);

    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    /** **/
    const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
    const [selectedGlobalUsers, setSelectedGlobalUsers] = useState<any[]>([]);

    const [editUserVisible, setEditUserVisible] = useState<boolean>(false);
    const [addUsersVisible, setAddUsersVisible] = useState<boolean>(false);
    const [showDeleteUsersDialog, setShowDeleteUsersDialog] = useState<boolean>(false);
    const [showMoveUserToRoleDialog, setShowMoveUsersToRoleDialog] = useState<boolean>(false);
    const [mayEditUM, setMayEditUM] = useState<boolean>(false);
    const [maySeeAllUsers, setMaySeeAllUsers] = useState<boolean>(false);
    const [reloadUsers, setReloadUsers] = useState<boolean>(false);

    /** **/
        // Kein Multiselect vorgesehen
    const [selectedRole, setSelectedRole] = useState<Nullable<Role>>(null);
    const [reloadRoles, setReloadRoles] = useState<boolean>(false);
    const [showRoleDialog, setShowRoleDialog] = useState<boolean>(false);

    const [viewButtonsRerender, setViewButtonsRerender] = useState<boolean>(true);

    useEffect(() => {
        if (checkPermission(permissions.isAdmin)) {
            setMayEditUM(true);
        }
        if (checkPermission(permissions.isAdmin)) {
            setMaySeeAllUsers(true);
        }

        if (checkPermission(permissions.isAdmin)) {
            setIsAdmin(true);
        }
    }, []);

    const editUser = () => {
        setEditUserVisible(true);
        setSelectedUsers([]);
        setSelectedGlobalUsers([]);
    };

    // Add user who is not already added to this project
    const addUser = () => {
        setAddUsersVisible(true);
    };

    const menuItems = [
        // {
        //     id: 'users',
        //     label: t('userManagement:global.users'),
        //     icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faUser}/>,
        //     component: <UsersComponents
        //         type={'user'}
        //         projectId={props.project ? props.project.id : null}
        //         reloadUsers={reloadUsers}
        //         setReloadUsers={setReloadUsers}
        //         mayEditUM={mayEditUM}
        //         editUserVisible={editUserVisible}
        //         setEditUserVisible={setEditUserVisible}
        //         addUsersVisible={addUsersVisible}
        //         setAddUsersVisible={setAddUsersVisible}
        //         showDeleteUsersDialog={showDeleteUsersDialog}
        //         setShowDeleteUsersDialog={setShowDeleteUsersDialog}
        //         selectedUsers={selectedUsers}
        //         setSelectedUsers={setSelectedUsers}
        //         setSelectedRole={setSelectedRole}
        //         setShowRoleDialog={setShowRoleDialog}
        //     />,
        //     visible: props.project !== null,
        //     buttons: (
        //         <ViewButtons title={t('')} items={[
        //             makeButtonItem(buttonRemoveUsersFromProject, t, () => {
        //                 setShowDeleteUsersDialog(true);
        //             }, mayEditUM, !(selectedUsers && selectedUsers.length > 0)),
        //             makeButtonItem(buttonMoveUsersToRole, t, () => {
        //                 setShowMoveUsersToRoleDialog(true);
        //             }, mayEditUM, !(selectedUsers && selectedUsers.length > 0)),
        //             makeButtonItem(buttonCreateUser, t, editUser, mayEditUM),
        //             makeButtonItem(buttonAddUser, t, addUser, maySeeAllUsers),
        //             makeButtonItem(buttonCreateRole, t, () => {
        //                 setShowRoleDialog(true);
        //             }, mayEditUM)
        //         ]} rerender={viewButtonsRerender}
        //         />
        //     )
        // },
        // {
        //     id: 'roles',
        //     label: t('userManagement:global.roles'),
        //     icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faUserTag}/>,
        //     component: <Roles
        //         project={props.project}
        //         mayEditUM={mayEditUM}
        //         selectedRole={selectedRole}
        //         setSelectedRole={setSelectedRole}
        //         reloadRoles={reloadRoles}
        //         setReloadRoles={setReloadRoles}
        //         showRoleDialog={showRoleDialog}
        //         setShowRoleDialog={setShowRoleDialog}
        //     />,
        //     visible: props.project !== null,
        //     buttons: (
        //         <ViewButtons title={t('')} items={[
        //             makeButtonItem(buttonCreateRole, t, () => {
        //                 setShowRoleDialog(true);
        //             }, mayEditUM)
        //         ]} rerender={viewButtonsRerender}
        //         />
        //     )
        // },
        {
            id: 'admins',
            label: t('userManagement:global.globalUsers'),
            icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faUserSecret}/>,
            component: <UsersComponents
                type={'admin'}
                projectId={null}
                reloadUsers={reloadUsers}
                setReloadUsers={setReloadUsers}
                mayEditUM={mayEditUM}
                editUserVisible={editUserVisible}
                setEditUserVisible={setEditUserVisible}
                addUsersVisible={addUsersVisible}
                setAddUsersVisible={setAddUsersVisible}
                showDeleteUsersDialog={showDeleteUsersDialog}
                setShowDeleteUsersDialog={setShowDeleteUsersDialog}
                selectedUsers={selectedGlobalUsers}
                setSelectedUsers={setSelectedGlobalUsers}
            />,
            visible: isAdmin,
            buttons: (
                <ViewButtons title={t('')} items={[
                    // makeButtonItem(buttonRemoveGlobalUsers, t, () => {
                    //     setShowDeleteUsersDialog(true);
                    // }, mayEditUM, !(selectedGlobalUsers && selectedGlobalUsers.length > 0)),
                    makeButtonItem(buttonCreateAdmin, t, editUser, mayEditUM),
                    // makeButtonItem(buttonAddAdmin, t, addUser, mayEditUM),
                ]} rerender={viewButtonsRerender}
                />
            )
        },
        // {
        //     id: 'globalRoles',
        //     label: t('userManagement:global.globalRoles'),
        //     icon: <FontAwesomeIcon className='sidebar-icon mr-2' icon={faUserTag}/>,
        //     component: <GlobalRolesOverview/>,
        //     visible: props.project === null && isAdmin
        // },
    ];

    return (
        <>
            {/* 0: Users, 3: Admins*/}
            <LayoutMainContentTabMenu items={menuItems} activeIndex={0} onTabChange={() => {
                setViewButtonsRerender(!viewButtonsRerender);
            }}/>
        </>
    );
};

export default UserManagement;
