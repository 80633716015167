import React, {useEffect, useRef, useState} from 'react';
import {addIdToHash, removeIdFromHash, saveCheck} from '../functions/Global';
import CustomDialog, {CustomDialogHeaderType} from '../global/CustomDialog';
import {t} from 'i18next';
import {PersonEditForm} from './PersonEditForm';
import {useTranslation} from 'react-i18next';
import {showMessageOnError} from '../global/CustomToast';
import {Menu} from 'primereact/menu';
import {Button} from 'primereact/button';
import {Person} from "../functions/Persons";

type Props = {
    selected?: Person,
    setSelected?: any,
    show: boolean,
    setShow: (data: boolean) => void,
    onFinished?: (data: Person) => void,
}
export const PersonDialogEditForm = (props: Props): JSX.Element => {
    const {t} = useTranslation(['common']);

    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [buttons, setButtons] = useState<any[]>([]);
    const [close, setClose] = useState<boolean>(false);

    useEffect(() => {
        if (props.selected) {
            addIdToHash(props.selected.id);
        }
    }, [props.selected]);

    const onCancel = () => {
        if (props.setSelected) props.setSelected(undefined);
        if (props.setShow) props.setShow(false);
        removeIdFromHash();
    };

    return (
        <CustomDialog
            className={'edit-dialog'}
            header={props.selected ? t('persons:edit') : t('persons:create')}
            headerType={props.selected ? CustomDialogHeaderType.Update : CustomDialogHeaderType.Create}
            onCancel={() => {
                if (isDirty) {
                    saveCheck(t, onCancel);
                } else {
                    onCancel();
                }
            }}
            visible={typeof props.show === 'boolean' ? props.show : false}
            customButtons={buttons}
            maximized={true}
            showFooter={true}
            formName={'editParcelForm'}>
            <PersonEditForm data={props.selected} setVisible={props.show}
                            setIsDirty={setIsDirty}
                            onFinished={(data) => {
                                props.setShow(false);
                                if (props.setSelected) {
                                    props.setSelected(undefined);
                                }

                                if (props.onFinished) {
                                    props.onFinished(data);
                                    removeIdFromHash();
                                }
                            }}/></CustomDialog>
    );
};
