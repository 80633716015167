import React, {useState, useEffect, useRef} from 'react';
import {classNames} from 'primereact/utils';
import {useNavigate, useParams} from 'react-router-dom';
import Topbar from './global/Topbar';
import Breadcrumb from './global/Breadcrumb';
import Menu from './global/Menu';
import PrimeReact from 'primereact/api';
import {Tooltip} from 'primereact/tooltip';
import {checkPermission} from '../functions/functionLibrary';
import {permissions} from '../config/permissions';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ConfirmDialog} from 'primereact/confirmdialog';
import {getMyPermissions} from '../components/functions/UserManagement';
import {
    faCog,
    faFolderClosed,
    faHome,
    faUsersCog,
    faMapLocation,
    faGears,
    faPerson,
    faFileImport
} from '@fortawesome/free-solid-svg-icons';
import CurrentUserSettings from '../components/userManagement/CurrentUserSettings';
import UserManagement from './UserManagement';
import {
    getSpatialBookmark,
    getUserFromLocalStorage,
    setUserSettings
} from '../components/functions/Global';
import {showMessageOnError} from '../components/global/CustomToast';
import {hideWaitAnimation, showWaitAnimation} from '../components/global/CustomWaitAnimation';
import {SystemManagement} from './SystemManagement';
import store from "../redux/store";
import {PersonsOverview} from "../components/persons/PersonOverview";
import LastImport from "./global/LastImport";
import PcToPlz from "./global/PcToPlz";

type Props = {
    keycloakToken: any,
    currentUser: any,
    colorScheme?: string
}
const App = (props: Props) => {
    // Primefaces
    const user = getUserFromLocalStorage();
    const user_menu = (typeof user === 'undefined' || typeof user.settings === 'undefined') ? null : user.settings.menu;
    store.getState();//drinlassen
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);
    const [menuMode, setMenuMode] = useState('sidebar');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [ripple, setRipple] = useState(true);
    const [sidebarStatic, setSidebarStatic] = useState(user_menu || false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(user_menu || false);
    const [pinActive, setPinActive] = useState(user_menu || false);
    const [activeInlineProfile, setActiveInlineProfile] = useState(false);
    const [resetActiveIndex, setResetActiveIndex] = useState<boolean>(false);
    const copyTooltipRef = useRef<any>();


    // DMT
    const {t} = useTranslation(['common']);
    const keycloakToken = props.keycloakToken;
    // const [selectedProject, setSelectedProject] = useState<any>(null);
    const [permissionsLoaded, setPermissionsLoaded] = useState<boolean>(false);
    const [customBreadcrumb, setCustomBreadcrumb] = useState<any>(null);
    // Wenn man nur einem Projekt zugewiesen ist, sollte man den Menupunkt 'Projekte' nicht sehen können
    const [hideMenuitemProjects, setHideMenuitemProjects] = useState<boolean>(false);

    const navigate = useNavigate();
    const params = useParams();
    PrimeReact.ripple = true;

    const theme = localStorage.getItem('Theme');

    const [colorScheme, setColorScheme] = useState(theme !== null && theme !== 'undefined' ? theme : 'dark');
    const hash_geomap = '#geomap';

    useEffect(() => {
        localStorage.removeItem('permissions');
        if (location.hash === '') {
            navigate('/#auditors');
        }
    }, []);

    useEffect(() => {
        let projectId: any = 'none';
        _getMyPermissions(projectId).then(() => {
            const userSettings = {...props.currentUser};
            if (projectId && props.currentUser.settings && props.currentUser.settings[projectId])
                userSettings.settings = props.currentUser.settings[projectId];
            localStorage.setItem('user', JSON.stringify(userSettings));
            setAvailableMenuOptions(menuOptions);
            hideWaitAnimation();
        });
    }, []);

    const _getMyPermissions = async (projectId: string) => {
        await getMyPermissions(projectId).then(result => {
            if (result.error) {
                showMessageOnError(t('error'), result.error);
            } else {
                localStorage.setItem('permissions', JSON.stringify(result));
                setAvailableMenuOptions(menuOptions);
                setPermissionsLoaded(true);
            }
        });
    };

    const onRightMenuClick = () => {
        rightMenuClick = true;
    };

    const onRightMenuActiveChange = (active: any) => {
        setRightMenuActive(active);
    };

    let rightMenuClick: any;
    let configClick: any;
    let menuClick: any;
    let searchClick: boolean = false;
    let topbarItemClick: any;

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        setResetActiveIndex(true);
        setMenuActive(false);
    }, [menuMode]);

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!topbarItemClick) {
            setTopbarMenuActive(false);
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
                setResetActiveIndex(true);
            }

            // if (overlayMenuActive || staticMenuMobileActive) {
            //     setOverlayMenuActive(false);
            //     setStaticMenuMobileActive(false);
            // }

            hideOverlayMenu();
            unblockBodyScroll();
        }

        if (!rightMenuClick) {
            setRightMenuActive(false);
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        topbarItemClick = false;
        menuClick = false;
        configClick = false;
        rightMenuClick = false;
        searchClick = false;
    };

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    };

    const onMenuButtonClick = (event: any) => {
        menuClick = true;

        // if (isOverlay()) {
        //     setOverlayMenuActive((prevState: any) => !prevState);
        // }

        if (isDesktop()) {
            setStaticMenuDesktopInactive((prevState) => !prevState);
        } else {
            setStaticMenuMobileActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const hideOverlayMenu = () => {
        // setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const onTopbarItemClick = (event: any) => {
        topbarItemClick = true;
        setTopbarMenuActive((prevState) => !prevState);
        hideOverlayMenu();
        event.preventDefault();
    };

    useEffect(() => {
        setUserSettings('menu', sidebarStatic).then();
    }, [sidebarStatic]);

    const onToggleMenu = (event: any) => {
        menuClick = true;

        // if (overlayMenuActive) {
        //     setOverlayMenuActive(false);
        // }

        if (sidebarActive) {
            setSidebarStatic((prevState: any) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarMouseOver = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setSidebarActive(isDesktop());
            setTimeout(() => {
                setPinActive(isDesktop());
            }, 200);
        }
    };

    const onSidebarMouseLeave = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setTimeout(() => {
                setSidebarActive(false);
                setPinActive(false);
            }, 250);
        }
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onChangeActiveInlineMenu = (event: any) => {
        setActiveInlineProfile((prevState) => !prevState);
        event.preventDefault();
    };

    const onRootMenuItemClick = () => {
        setMenuActive((prevState) => !prevState);
    };

    const onMenuItemClick = (event: any) => {
        console.log('onMenuItemClick', event);
        setCustomBreadcrumb(null);
        if (!event.item.items) {
            hideOverlayMenu();
            setResetActiveIndex(true);
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isOverlay = () => {
        return menuMode === 'overlay';
    };

    const isDesktop = () => {
        return window.innerWidth > 991;
    };

    /* const onInputClick = () => {
        searchClick = true;
    };

    const breadcrumbClick = () => {
        searchClick = true;
        setSearchActive(true);
    }; */

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const layoutClassName = classNames('layout-wrapper', {
        'layout-static': menuMode === 'static',
        'layout-overlay': menuMode === 'overlay',
        'layout-overlay-active': true, //overlayMenuActive,
        'layout-slim': menuMode === 'slim',
        'layout-horizontal': menuMode === 'horizontal',
        'layout-active': menuActive,
        'layout-mobile-active': staticMenuMobileActive,
        'layout-sidebar': menuMode === 'sidebar',
        'layout-sidebar-static': menuMode === 'sidebar' && sidebarStatic,
        'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static',
        'p-ripple-disabled': !ripple
    });

    const menuOptions = [
        {
            id: 'auditors',
            label: 'Auditoren', // t('map'),
            icon: <FontAwesomeIcon className="sidebar-icon" icon={faPerson}/>,
            command: () => {
                window.location.hash = '#auditors';
            },
            projectRequired: false,
            component: <PersonsOverview/>,
            visible: () => {
                return true;
            }
        },
        // {
        //     id: 'projects',
        //     label: t('projects'),
        //     icon: <FontAwesomeIcon className="sidebar-icon" icon={faHome}/>,
        //     command: () => {
        //         setSelectedProject(null);
        //         navigate('/#projects');
        //     },
        //     projectRequired: false,
        //     component:
        //         <ProjectOverview
        //             keycloakToken={keycloakToken}
        //             setSelectedProject={setSelectedProject}
        //             setHideMenuitemProjects={setHideMenuitemProjects}
        //         />,
        //     permission: null,
        //     visible: () => {
        //         return !hideMenuitemProjects;
        //     }
        // },
        // {
        //     id: 'geomap',
        //     label: 'Geomap', // t('map'),
        //     icon: <FontAwesomeIcon className="sidebar-icon" icon={faMapLocation}/>,
        //     command: () => {
        //         window.location.hash = hash_geomap;
        //     },
        //     projectRequired: true,
        //     component: <GeoData project={selectedProject} configActive={true} alarm={[]} onConfigButtonClick={() => {
        //     }} onConfigClick={() => {
        //     }} show={true}/>,
        //     visible: () => {
        //         return (selectedProject !== null && selectedProject.location !== null);
        //         //return true;
        //     }
        // },
        {
            id: 'configuration',
            label: t('configuration'),
            icon: <FontAwesomeIcon className="sidebar-icon" icon={faCog}/>,
            projectRequired: true,
            // Innerhalb eines Projektes ist das Konfigurationsmenü immer sichtbar, außerhalb nur für Admins und
            // Projektersteller
            visible: () => {
                return checkPermission(permissions.isAdmin);
            },
            items: [
                // {
                //     id: 'project',
                //     label: t('projectManagement:global.menuName'),
                //     icon: <FontAwesomeIcon className="sidebar-icon" icon={faFolderClosed}/>,
                //     command: () => {
                //         window.location.hash = '#project';
                //     },
                //     projectRequired: true,
                //     component: <ProjectManagement project={selectedProject} setProject={setSelectedProject}/>,
                //     visible: () => {
                //         return (selectedProject !== null
                //             ? true
                //             : checkPermission(permissions.isAdmin) || (checkPermission(permissions.createProject)));
                //     }
                // },
                {
                    id: 'userManagement',
                    label: t('userManagement:global.menuName'),
                    icon: <FontAwesomeIcon className="sidebar-icon" icon={faUsersCog}/>,
                    command: () => {
                        window.location.hash = '#userManagement';
                    },
                    projectRequired: true,
                    component: <UserManagement/>,
                    visible: () => {
                        return checkPermission(permissions.isAdmin);
                    }
                },
                {
                    id: 'pcZuordnung',
                    label: 'PC / PLZ Zuordnung',
                    icon: <FontAwesomeIcon className="sidebar-icon" icon={faFileImport}/>,
                    command: () => {
                        window.location.hash = '#pcZuordnung';
                    },
                    projectRequired: true,
                    component: <PcToPlz/>,
                    visible: () => {
                        return checkPermission(permissions.isAdmin);
                    }
                },
                // {
                //     id: 'systemManagement',
                //     label: t('systemManagement:global.menuName'),
                //     icon: <FontAwesomeIcon className="sidebar-icon" icon={faGears}/>,
                //     command: () => {
                //         window.location.hash = '#systemManagement';
                //     },
                //     projectRequired: true,
                //     component: <SystemManagement/>,
                //     visible: () => {
                //         return (selectedProject === null && checkPermission(permissions.isAdmin));
                //     }
                // },
            ]
        },
    ];


    const [availableMenuOptions, setAvailableMenuOptions] = useState(menuOptions);

    const getComponent = () => {
        let elm = null;
        let hash = window.location.hash;
        if (window.location.hash.indexOf('_') > -1)
            hash = window.location.hash.substring(0, window.location.hash.indexOf('_'));
        switch (hash) {
            case '#usersettings':
                elm = <CurrentUserSettings/>;
                break;
        }
        menuOptions.forEach((item: any) => {
            if (item.items) {
                item.items.forEach((item2: any) => {
                    if (item2.visible() && '#' + item2.id === hash) {
                        elm = item2.component;
                        return;
                    }
                });
            } else if (item.visible() && '#' + item.id === hash) {
                elm = item.component;
                return;
            }
        });
        return elm;
    };

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            {/* Add ConfirmDialog to DOM: Breaking change in PrimeReact 8.0.0, because of changes in the import package of
             the ReactDOM.render method of React version 18. See: https://github.com/primefaces/primereact/issues/2718*/}
            <ConfirmDialog/>
            {permissionsLoaded && <>
                <Tooltip
                    ref={copyTooltipRef}
                    target=".block-action-copy"
                    position="bottom"
                    content="Copied to clipboard"
                    event="focus"
                />
                <div className="layout-main ">
                    <Topbar
                        items={availableMenuOptions}
                        menuMode={menuMode}
                        colorScheme={props.colorScheme}
                        menuActive={menuActive}
                        topbarMenuActive={topbarMenuActive}
                        activeInlineProfile={activeInlineProfile}
                        onTopbarItemClick={onTopbarItemClick}
                        onMenuButtonClick={onMenuButtonClick}
                        onSidebarMouseOver={onSidebarMouseOver}
                        onSidebarMouseLeave={onSidebarMouseLeave}
                        onToggleMenu={onToggleMenu}
                        onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                        onMenuClick={onMenuClick}
                        onMenuItemClick={onMenuItemClick}
                        onRootMenuItemClick={onRootMenuItemClick}
                        resetActiveIndex={resetActiveIndex}
                    />
                    <Menu
                        colorScheme={colorScheme}
                        setColorScheme={setColorScheme}
                        model={availableMenuOptions}
                        onRootMenuItemClick={onRootMenuItemClick}
                        onMenuItemClick={onMenuItemClick}
                        onToggleMenu={onToggleMenu}
                        onMenuClick={onMenuClick}
                        menuMode={menuMode}
                        menuActive={menuActive}
                        sidebarActive={sidebarActive}
                        sidebarStatic={sidebarStatic}
                        pinActive={pinActive}
                        onSidebarMouseLeave={onSidebarMouseLeave}
                        onSidebarMouseOver={onSidebarMouseOver}
                        activeInlineProfile={activeInlineProfile}
                        onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                        resetActiveIndex={resetActiveIndex}
                        setCustomBreadcrumb={setCustomBreadcrumb}
                        // hidePin={hidePin}
                    />

                    <div className={'layout-main-content ' + window.location.hash.replace('#', '')}>
                        {getComponent()}
                    </div>

                    <Breadcrumb
                        routes={availableMenuOptions}
                        onMenuButtonClick={onMenuButtonClick}
                        menuMode={menuMode}
                        sideBarStatic={sidebarStatic}
                        colorScheme={colorScheme}
                        customBreadcrumb={customBreadcrumb}
                    />
                    <LastImport/>
                </div>
            </>}
        </div>
    );
};

export default App;
