import React, {forwardRef} from 'react';

type Props = {
    className?: string,
    children: any,
    flex: boolean,
    innerRef?: any,
}
export const ViewButtonsContainer = (props: Props): JSX.Element => {
    return (
        <div
            ref={props.innerRef}
            className={'view-buttons flex justify-content-end' + (props.className ? ' ' + props.className : '')}
            style={{height: '6%'}}>
            {props.flex &&
            <div className='flex'>
                {props.children}
            </div>}
            {!props.flex &&
            props.children}
        </div>
    );
};
