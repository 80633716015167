import React, {useState} from 'react';
import {createRef} from 'react';
import {ProgressBar} from 'primereact/progressbar';

let toastRef: any = createRef();

export const updateToastRef = (ref: any) => {
    toastRef = ref;
    // console.log('>>>>toastRef updated<<<< Not null: ', toastRef !== null);
};

export function showMessageOnSuccess(title: string, message: string) {
    if (toastRef && toastRef.current)
        toastRef.current.show({
            severity: 'success',
            summary: title,
            detail: message,
            life: 4000
        });
}

export function showMessageOnError(title: string, message: string, duration?: number) {
    if (toastRef && toastRef.current)
        toastRef.current.show({
            severity: 'error',
            summary: title,
            detail: message,
            sticky: !duration,
            life: duration ? duration : undefined
        });
}

export function showMessageOnWarn(title: string, message: string) {
    if (toastRef && toastRef.current)
        toastRef.current.show({
            severity: 'warn',
            summary: title,
            detail: message,
            life: 5000
        });
}

export function showProgressToast(value: number) {

    if (toastRef && toastRef.current)
        return toastRef;
    toastRef.current.show({
        severity: 'warn',
        sticky: true,
        content: (
            <div className="flex flex-column" style={{flex: '1'}}>
                <div className="text-center">
                    <i className="pi pi-exclamation-triangle" style={{fontSize: '2rem'}}></i>
                    <h4>Lüfter wurde gestoppt. Bitte warten.</h4>
                    <ProgressBar value={value}></ProgressBar>
                </div>
            </div>
        )
    });
}
