import {configureStore, isRejected, Middleware, MiddlewareAPI} from '@reduxjs/toolkit';
import keycloakReducer from './slices/KeycloakSlice';

import {setupListeners} from '@reduxjs/toolkit/query';
import {injectedPersonApi} from './rtk/injectedPersonApi';
import {baseApi} from './rtk/baseApi';
import {showMessageOnError} from '../components/global/CustomToast';


export const rtkQueryErrorLogger: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        // isRejectedWithValue Or isRejected
        if (isRejected(action)) {
            console.log(action); // get all data from rejected request
            switch (action.payload?.status) {
                case 400:
                case 401:
                case 403:
                case 500:
                    showMessageOnError('Error on ' + action.meta.arg.endpointName, JSON.stringify(action.payload.data));
                    break;
            }
        }

        return next(action);
    };

const store: any = configureStore({
    reducer: {
        keycloakData: keycloakReducer,
        [injectedPersonApi.reducerPath]: injectedPersonApi.reducer,
        [baseApi.reducerPath]: baseApi.reducer,

    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            injectedPersonApi.middleware,
            baseApi.middleware,
            rtkQueryErrorLogger),
});

setupListeners(store.dispatch);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export default store;
