// RightGuard Production
export const keycloakConfig = {
    "realm": "auditor-db",
    "url": "https://security.dev.dmt-group.com",
    "clientId": "web-app",
};
const settings = {
    // apiUrl: process.env.NODE_ENV === 'development' ? 'http://localhost:8182' : 'https://api.auditor.testing.dmt-group.com',
    apiUrl: 'https://api.auditor.testing.dmt-group.com',
    keycloakAuthUrl: keycloakConfig.url + '/realms/' + keycloakConfig.realm + '/protocol/openid-connect/token',
    logoutUrl: keycloakConfig.url + '/realms/' + keycloakConfig.realm + '/protocol/openid-connect/logout',
    logoutRedirect: process.env.NODE_ENV === 'development' ? 'http://localhost:1841/' : 'https://auditor.testing.dmt-group.com/',
    ooApiUrl: 'https://api.dev.dmt-group.com',
}

export default settings;
