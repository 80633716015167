import React, {useEffect, useState} from 'react';
import {BreadCrumb} from 'primereact/breadcrumb';
import {Project} from '../../components/functions/Project';
import CustomImage from '../../components/global/CustomImage';
import {useTranslation} from 'react-i18next';
import {Image} from 'primereact/image';
import settings from '../../config/settings';

type Props = {
    routes: any,
    onMenuButtonClick: (event: any) => void,
    menuMode: string,
    sideBarStatic: boolean,
    colorScheme: string | null
    customBreadcrumb: any;
}
const Breadcrumb = (props: Props) => {
    const {t} = useTranslation(['common']);
    let items: any = [];

    switch (window.location.hash) {
        case '#usersettings':
            items.push({label: t('settings')});
            break;
    }
    const [bcItems, setBcItems] = useState([items]);

    useEffect(() => {
        items = [];

        switch (window.location.hash) {
            case '#usersettings':
                items.push({label: t('settings')});
                break;
        }
        props.routes.forEach((item: any) => {
            // console.log(item.visible, '#' + item.id, '===', window.location.hash);
            let location = window.location.hash;
            if (location.indexOf('_') > -1)
                location = location.substring(0, location.indexOf('_'));

            if (item.items && item.items.length > 0) {
                item.items.forEach((item2: any) => {
                    // console.log(item2.visible, '#' + item2.id, '===', window.location.hash);
                    if (item2.visible && '#' + item2.id === location) {
                        items.push(item);
                        // console.log(item2.visible, '#' + item2.id, '===', window.location.hash);
                        items.push(item2);
                        return;
                    }
                });
                return;
            } else if (item.visible && '#' + item.id === location) {
                // console.log(item.visible, '#' + item.id, '===', window.location.hash);
                item.url = '#' + item.id;
                items.push(item);
                return;
            }
        });
        if (props.customBreadcrumb === null)
            setBcItems(items);
    }, [window.location.hash]);

    useEffect(() => {
        if (props.customBreadcrumb !== null && props.customBreadcrumb.length > 0) {
            console.log('Update bc 1:', props.customBreadcrumb);
            const testItem = items;
            props.customBreadcrumb.forEach((itemC: any) => {
                testItem.push(itemC);
            });
            setBcItems(testItem);
            console.log('Update bc 2:', testItem);
        }
    }, [props.customBreadcrumb]);

    return (
        <div className="layout-breadcrumb-container">
            <div className="layout-topbar">
                <div className="layout-topbar-left">
                    <button className="topbar-menu-button p-link ml-0" onClick={props.onMenuButtonClick}>
                        <i className="pi pi-chevron-right"></i>
                    </button>
                </div>
            </div>
            <div className="layout-breadcrumb-left-items card hidden lg:inline-block">
                {bcItems && <BreadCrumb
                    model={bcItems}
                    className="layout-breadcrumb m-auto"
                    home={{
                        template: <div style={{'fontSize': '1.25rem'}}>{props.sideBarStatic ? '' : ' '}<Image
                            src={settings.logoutRedirect + 'TUEV-NORD-text-' + props.colorScheme + '.svg'} height="25px"
                            alt='APP logo'/></div>
                    }}/>}
            </div>
        </div>
    );
};

export default Breadcrumb;

