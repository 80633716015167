/*
 * KeyCloakService.tsx
 * Author: fwunderlich
 * Date: 19.01.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useRef} from 'react';
import Keycloak from 'keycloak-js';
import {keycloak} from '../functions/keycloak';
import settings from '../config/settings';
import keycloakfetch from '../functions/keycloakfetch';
import store from "../redux/store";
import {setKeycloakIdToken, setKeycloakRefreshToken, setKeycloakToken} from "../redux/slices/KeycloakSlice";


type Props = {
    onAuth: (token: string) => void
    onSessionExpired: () => void
}



const updateAccessToken = async (url: undefined) => {
    const state=store.getState();
    const updateKeycloak = async () => {
        try{
            const refreshToken = state.keycloakData.keycloak.keycloakRefreshToken|| '';
            const paramsBody = 'client_id=' + keycloak.clientId + '&grant_type=refresh_token&refresh_token=' + refreshToken;
            const requestOptions = {
                method: 'POST',
                useDefaultXhrHeader: false,
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                body: paramsBody
            };

            const response = await fetch(settings.keycloakAuthUrl, requestOptions);
            if (response.status === 200||response.status === 302) {
                console.log('Token updated!');
                const data = await response.json();
                store.dispatch(setKeycloakToken(data.access_token));
                store.dispatch(setKeycloakRefreshToken(data.refresh_token));
                return keycloakfetch.get(url);
            }

            return response;
        }catch(e){
            console.log(e)
        }

    };

    const token = state.keycloakData.keycloak.keycloakToken;
    const tokenPayload = parseJwt(token);
    const tokenExpiredTime = tokenPayload.exp;
    // console.log(tokenExpiredTime, ' (access-token expired time)');

    const now = new Date().getTime() / 1000 | 0;
    // console.log(now, " (now)");

    if ((tokenExpiredTime - now) < 30) {
        await updateKeycloak();
        return true;
    }
    return false;
};

const parseJwt = (token: string) => {
    let value;
    if(token&&token.length>0&&typeof token!== 'undefined'){
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        value=JSON.parse(jsonPayload);
    }

    return value;
};

const KeycloakService = (props: Props): JSX.Element => {


    const logMyLogin = () => {
        keycloakfetch.get(settings.apiUrl + '/logMyLogin').then((result: any) => {
            if (result) {
                localStorage.setItem('loginRequired', '0');
            }
        });
    };

    const initKeycloak = async (keycloak: Keycloak.KeycloakInstance) => {
        console.info('initKeycloak');
        let token: string = '';
        await keycloak.init({onLoad: 'check-sso', checkLoginIframe: false}).then((auth) => {
            console.info('Authenticate auth: ', auth);
            if (!auth) {
                keycloak.init({onLoad: 'login-required'});
                localStorage.setItem('loginRequired', '1');
            } else {
                console.info('keycloak: ', keycloak)
                console.info('Authenticated');
                // userLogin();
                console.log(keycloak)
                token = keycloak.token || '';
                store.dispatch(setKeycloakToken(token));
                store.dispatch(setKeycloakIdToken(keycloak.idToken || ''));
                store.dispatch(setKeycloakRefreshToken(keycloak.refreshToken || ''));
                if (localStorage.getItem('loginRequired') === '1') {
                    logMyLogin();
                }
            }
        }).catch((e) => {
            console.log(e);
            console.error('Authenticated Failed');
        });
        return token;
    };

    const isRefreshTokenExpired = () => {
        const state=store.getState();
        const parsedToken=parseJwt(state.keycloakData.keycloak.keycloakRefreshToken);
        const tokenExpiredTime = parsedToken.exp;
        const now = new Date().getTime() / 1000 | 0;
        return (tokenExpiredTime - now) < 0;
    };

    useEffect(() => {
        (async () => {
            const token = await initKeycloak(keycloak);
            props.onAuth(token);

            if (token !== '') {
                const interval = setInterval(() => {
                    if (isRefreshTokenExpired()) {
                        props.onSessionExpired();
                        clearInterval(interval);
                    }
                }, 200000);

                return () => clearInterval(interval);
            }
        })();
    }, []);

    return (<></>);
};

export {
    updateAccessToken,
    KeycloakService,
    parseJwt
};
