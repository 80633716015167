import React from 'react';
import {Button} from 'primereact/button';
import {faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

type Props = {
    label: string
    className?: string,
    onClick?: any,
    icon?: any,
    name?: string,
    id?: string,
    disabled?: boolean,
    type?: string,
    style?: any,
    submit?: string,
}
export const ViewButton = (props: Props): JSX.Element => {
    return (
        <div key={props.label} className='col-fixed pr-0'>
            <Button
                type={props.submit ? 'submit' : 'button'}
                form={props.submit}
                key={'butt_' + props.label}
                id={props.id ? props.id : props.label.replaceAll(' ', '')}
                name={props.name ? props.name : props.label.replaceAll(' ', '')}
                className={'p-button ' + (props.submit ? 'p-button-noshadow' : 'p-button-outlined') + (props.className ? ' ' + props.className : '')}
                disabled={props.disabled}
                style={props.style}
                onClick={props.onClick}>
                {props.icon && typeof props.icon === 'string' &&
                <i className={props.icon + ' mr-2'}/>}
                {props.icon && typeof props.icon !== 'string' &&
                <FontAwesomeIcon className='sidebar-icon mr-2' icon={props.icon}/>}
                <p className='my-0'>{props.label}</p>
            </Button>
        </div>
    );
};
