import {CirclePicker} from "react-color";
import Input from "../global/Input";
import {Button} from "primereact/button";
import {getUserFromLocalStorage} from "../functions/Global";
import moment from "moment";
import React, {useEffect, useState} from "react";
import keycloakfetch from "../../functions/keycloakfetch";
import settings from "../../config/settings";
import {showMessageOnError, showMessageOnSuccess} from "../global/CustomToast";
import {useTranslation} from "react-i18next";
import {CustomConfirmDialog} from "../global/CustomConfirmDialog";
import {checkPermission} from "../../functions/functionLibrary";
import {permissions} from "../../config/permissions";

type Props = {
    person_id: string,
    type: number,
    getCount: (count: number) => void
}

export const Protocoll = (props: Props) => {
    const {t} = useTranslation(['common', 'stammdaten']);

    const [protocoll, setProtocoll] = useState<any[]>([]);
    const [protocollNew, setProtocollNew] = useState<string>('');
    const [protocollNewColor, setProtocollNewColor] = useState<string>('ffffff');

    const firstAndLastName = getUserFromLocalStorage().first_name + ' ' + getUserFromLocalStorage().last_name;

    useEffect(() => {
        getProtokoll();
    }, []);
    useEffect(() => {
        props.getCount(protocoll.length);
    }, [protocoll]);

    const getProtokoll = () => {
        if (props.person_id) {
            keycloakfetch.get(settings.apiUrl + '/getProtokoll?person_id=' + props.person_id + '&type_id=' + props.type).then(result => {
                if (result) {
                    result = result.sort((a: any, b: any) => {
                        const date1 = new Date(a.datum);
                        const date2 = new Date(b.datum);

                        return date1 < date2 ? 1 : date1 > date2 ? -1 : 0;
                    });
                    setProtocoll(result);
                }
            });
        }
    };

    const createProtokoll = (data: any) => {
        keycloakfetch.post(settings.apiUrl + '/createProtokoll', data).then(result => {
            if (!result.error) {
                setProtocoll((prevState: any) => {
                    let temp = [...prevState];

                    temp.push(result);

                    temp = temp.sort((a: any, b: any) => {
                        const date1 = new Date(a.datum);
                        const date2 = new Date(b.datum);

                        return date1 < date2 ? 1 : date1 > date2 ? -1 : 0;
                    });

                    return temp;
                });
                showMessageOnSuccess(t('success'), 'Protokolleintrag gespeichert!');
            } else {
                showMessageOnError(t('error'), result.error);
            }
        });
    };

    const updateProtokoll = (data: any) => {
        keycloakfetch.put(settings.apiUrl + '/updateProtokoll', data).then(result => {
            console.log(result);
            if (!result.error) {
                setProtocoll((prevState: any) => {
                    const temp = [...prevState];

                    for (let i = 0; i < temp.length; i++) {
                        let item = temp[i];

                        if (item.id === result.id) {
                            item = {...result};
                            break;
                        }
                    }

                    return temp;
                });
                showMessageOnSuccess(t('success'), 'Protokolleintrag gespeichert!');
            } else {
                showMessageOnError(t('error'), result.error);
            }
        });
    };

    const buttonUpdateProtokoll = (data: any) => {
        return (<Button
            type={'button'}
            className="p-button p-button-text mx-1 overflow-visible" style={{minWidth: '20px'}}
            disabled={false}
            tooltip={t('save')}
            tooltipOptions={{position: 'top'}}
            onClick={() => {
                CustomConfirmDialog({
                    message: 'Möchten sie den Protokolleintrag wirklich ändern?',
                    header: 'Protokolleintrag speichern',
                    translation: t,
                    onConfirm: () => {
                        updateProtokoll(data);
                    }
                });
            }}>
            <i className="pi pi-save"/>
        </Button>);
    };


    const buttonDeleteProtokoll = (id: string) => {
        return (<Button
            type={'button'}
            className="p-button-danger p-button-text mx-1 overflow-visible" style={{minWidth: '20px'}}
            disabled={false}
            tooltip={t('delete')}
            tooltipOptions={{position: 'top'}}
            onClick={() => {
                CustomConfirmDialog({
                    message: 'Möchten sie den Protokolleintrag wirklich löschen?',
                    header: 'Protokolleintrag löschen',
                    translation: t,
                    onConfirm: () => {
                        deleteProtocoll(id);
                    }
                });
            }}>
            <i className="pi pi-trash"/>
        </Button>);
    };

    const deleteProtocoll = (id: string) => {
        keycloakfetch.delete(settings.apiUrl + '/deleteProtokoll', {protokoll_id: id}).then(result => {
            console.log(result);
            if (!result.error) {
                setProtocoll((prevState: any) => {
                    const temp = [...prevState];

                    for (let i = 0; i < temp.length; i++) {
                        const item = temp[i];

                        if (item.id === id) {
                            temp.splice(i, 1);
                            break;
                        }
                    }

                    return temp;
                });
                showMessageOnSuccess(t('success'), 'Protokolleintrag gelöscht!');
            } else {
                showMessageOnError(t('error'), result.error);
            }
        });
    };


    return (<div>
        {/*<h3>Neuer Protokolleintrag</h3>*/}
        {(checkPermission(permissions.isAdmin) || checkPermission(permissions.isProfitCenter)) &&
            <div className={'grid mt-4'}>
                <div className={'col-fixed'}>
                    <label><h5 className={'mb-2'}>Farbe</h5></label>
                    {/* <ColorPicker value={protocollNewColor} className={'color-picker-protocoll'}*/}
                    {/*             onChange={(e: any) => {*/}
                    {/*                 console.log(e);*/}
                    {/*                 setProtocollNewColor(e.value);*/}
                    {/*             }}></ColorPicker>*/}
                    <CirclePicker colors={['#ffffff', '#00ff00', '#ff0000']}
                                  onChange={(e) => {
                                      setProtocollNewColor(e.hex);
                                  }} color={protocollNewColor}/>
                </div>
                <div className={'col'}>
                    <Input edit={true} label={'Beschreibung'} name={'protokoll-text'}
                           type={'textarea'}
                           disabled={!checkPermission(permissions.isAdmin) && !checkPermission(permissions.isProfitCenter)}
                           value={protocollNew} onChange={(e: any) => {
                        setProtocollNew(e.target.value);
                    }}/>
                </div>
                <div className={'col-fixed mt-auto mb-1'} style={{width: '50px'}}>
                    <Button className={'p-btn'} icon={'pi pi-send'}
                            disabled={!protocollNew || protocollNew === ''}
                            onClick={() => {
                                if (protocollNew && protocollNew !== '') {
                                    const item = {
                                        farbcode: protocollNewColor && protocollNewColor !== 'ffffff' ? protocollNewColor : '',
                                        absender: getUserFromLocalStorage().first_name + ' ' + getUserFromLocalStorage().last_name,
                                        datum: new Date(),
                                        protokoll: protocollNew,
                                        person_id: props.person_id,
                                        type: props.type,
                                    };

                                    createProtokoll(item);

                                    setProtocollNew('');
                                    setProtocollNewColor('ffffff');
                                }
                            }}/>
                </div>
            </div>}
        <br/>
        {/*<h3>Protokoll</h3>*/}
        <table className={'protocoll-table w-full'}>
            <tbody>
            <tr>
                <th></th>
                <th>Absender / Datum</th>
                {/*<th>Datum</th>*/}
                <th>Kommentar</th>
                <th>Aktionen</th>
            </tr>
            {protocoll.map((item: any, index: number) => {
                return (<tr key={index}>
                    <td style={{
                        backgroundColor: item.farbcode ? item.farbcode : '',
                        width: '10px'
                    }}></td>
                    <td style={{verticalAlign: 'top', width: '250px'}}>
                        <div>{item.absender}</div>
                        <div>{moment(item.datum).format(t('dateformat'))}</div>
                    </td>
                    {/*<td>{moment(item.datum).format(t('dateformat'))}</td>*/}
                    <td><Input edit={true} name={'protokoll-' + index}
                               value={protocoll[index].protokoll}
                               type={'textarea'}
                               disabled={!checkPermission(permissions.isAdmin) && item.absender !== firstAndLastName}
                               onChange={(e: any) => {
                                   setProtocoll((prevState: any) => {
                                       const temp = [...prevState];

                                       temp[index].protokoll = e.target.value;

                                       return temp;
                                   });
                               }}/></td>
                    <td style={{verticalAlign: 'top', width: '50px'}}>
                        {(checkPermission(permissions.isAdmin) || item.absender === firstAndLastName) &&
                            <div
                                className={'flex'}>{buttonUpdateProtokoll(item)}{buttonDeleteProtokoll(item.id)}</div>}
                    </td>
                </tr>);
            })}
            </tbody>
        </table>
    </div>)
}
