import React, {useEffect, useRef, useState} from 'react';
import {usePcToPlzMutation} from "../../redux/rtk/injectedPersonApi";
import {useTranslation} from "react-i18next";
import {Button} from "primereact/button";
import {showMessageOnError, showMessageOnSuccess, showMessageOnWarn} from "../../components/global/CustomToast";
import {ProgressSpinner} from "primereact/progressspinner";

const PcToPlz = () => {
    const {t} = useTranslation(['common']);

    const inputFile: any = useRef(null);

    const [showLoading, setShowLoading] = useState<boolean>(false);

    const [pcToPlz, resultPcToPlz] = usePcToPlzMutation();

    useEffect(() => {
        setShowLoading(resultPcToPlz.isLoading);
    }, [resultPcToPlz.isLoading]);

    // useEffect(() => {
    //     if (resultPcToPlz.data) {
    //         let result = [];
    //
    //         for (let i = 0; i < resultPcToPlz.data.length; i++) {
    //             let rsi = resultPcToPlz.data[i];
    //             result.push(`${rsi.person_nr}: ${rsi.info}`)
    //         }
    //
    //         // showMessageOnWarn('Info', result.join('\n'));
    //     }
    // }, [resultPcToPlz.data]);

    const onAddDocument = () => {
        if (inputFile.current !== null) {
            inputFile.current.click();
        }
    };

    const getBase64 = (file: any, cb: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    const addTempFile = (e: any) => {
        setShowLoading(true);
        getBase64(e.target.files[0], (result: any) => {
            const arr = result.split('base64,');
            if (arr.length === 2) {

                const split = e.target.files[0].name.split('.');
                const extension = split[split.length - 1].toLowerCase();
                let file_type_id = 0;

                if (['xls', 'xlsx'].indexOf(extension) < 0) {
                    showMessageOnError(t('error'), t('errorMessages:fileExtensionNotSupportedError'));
                } else {
                    const data = arr[1];

                    pcToPlz({data: data}).then((result: any) => {
                        console.log(result);
                        if (!result.error) {
                            showMessageOnSuccess('Erfolgreich', result.message);
                        }
                    });
                }
            }
        });
    };

    return <>
        <div className={''}>
            <input
                type="file"
                id="file"
                ref={inputFile}
                accept={'.xls, .xlsx'}
                onInput={addTempFile}
                onClick={(e: any) => e.target.value = ''}
                style={{display: 'none'}}
            />
            <Button className={'p-button p-button-icon p-button-secondary ml-2'} icon={'pi pi-upload'}
                    onClick={onAddDocument} label={'PC -> PLZ Zuordnung'}/>
        </div>
        {showLoading && <div className={'spinner'}>
            <ProgressSpinner/>
        </div>}
    </>
};

export default PcToPlz;
