import {useEffect} from 'react';

type Props = {
    hasUnsavedChanges: boolean
}

const FormPrompt = (props: Props) => {
    useEffect(() => {
        const onBeforeUnload = (e: any) => {
            if (props.hasUnsavedChanges) {
                e.preventDefault();
                e.returnValue = '';
            }
        };
        window.addEventListener('beforeunload', onBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload);
        };
    }, [props.hasUnsavedChanges]);

    return null;
};

export default FormPrompt;
